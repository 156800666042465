import React from 'react'
import { Link } from "gatsby"


import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"


const aboutPage = ({ data, location }) => {
    return (
        <Layout location={location}>
        <googleanalytics />
        <SEO title="About Chris Reynolds" />
        <Nav />
        <div>
            <h1>About</h1>

            <p>My name is Chris Reynolds and I’ve been professionally tinkering with the Internet since 2003.</p>

            <p>I've worked as a webmaster, marketing manager, product manager and SEO, and released a number of <Link to="/projects">personal projects</Link> over the years.</p>

            <p>Currently I’m lucky enough to work as SEO Director for <a href="https://indeed.com/" target="_blank" rel="noreferrer">Indeed.com</a> in beautiful San Jose, California.</p>
            
            <p>Any views in this blog are my own and not those of my employer.</p>

            <p>Feel free to <Link to="/contact">get in touch</Link>.</p>

        </div>
      </Layout>
    )
}


export default aboutPage